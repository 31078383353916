@font-face {
  font-family: 'TCCUnityHeadlineBlack';
  src: url('./fonts/TCCC-UnityHeadlineBlack.ttf'), url('./fonts/TCCC-UnityHeadlineBlack.ttf') format('truetype'), url('./fonts/TCCC-UnityHeadlineBlack.ttf') format('svg');
  font-weight: normal;
  font-style: normal;
}

.App {
  color: white;
  font-family: 'TCCUnityHeadlineBlack';
  height: 100%;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


#style-2 {
  text-align: justify;
  padding-right: 1%;
}

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background-color: #F5F5F5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: green;
}

body {
  background: url("./images/blackimg.png");
  background-size: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading {
  height: 80vh;
  margin: auto;
  text-align: center;
  padding-top: 5px;
  width: 100%;
  display: flex;
}

.loading img {
  width: 300px;
  margin: auto;
}

/* footer  */
.footer {
  text-align: center;
  /* padding-top: 50px; */
}

.footerSigniture {
  padding-top: 30px;
  color: white;
}

.data {
  position: relative;
  /* top: 70px; */
}

.submit_Form {
  width: 50%;
  margin-top: 10px;
}

.langWrapper {
  display: flex;
  justify-content: space-between;
  justify-content: end;
}


.langWrapper button {
  background-color: transparent;
  color: white;
  font-size: 12px;
  width: fit-content;
}

.nav_link {
  display: inline-block;
  cursor: pointer;
}

.nav_link:after {
  content: '';
  width: 0px;
  height: 2px;
  display: block;
  background: white;
  transition: 300ms;
}

.nav_link:hover:after {
  width: 100%;
}

.aplication_form {
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.submitButton {
  border-radius: 12px;
  border-color: green;
  font-size: 18px;
  padding: 8px;
}

.submitButtonImg {
  width: 20px;
}

.popover_Button {
  background-color: transparent;
  border: none;
}

.popover_Button i {
  color: white;
  font-size: x-large;
  /* border-radius: 100%; */
  /* border: solid 2px white; */
}

.popover_img {
  width: 250px;
}

.alert {
  background-color: transparent;
  border-color: #d6e9c6;
  color: white;
}

.success-container {
  width: 50%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 80vh; */
}

.custom-file-upload {
  display: inline-block;
  padding: 30px 15px;
  cursor: pointer;
  background-color: white;
  color: rgb(121, 116, 116);
  border-radius: 5px;
}

.contact_us_info {
  text-align: center;

}

.contact_us_info a {
  text-decoration: none;
  color: white;
}

/* Hide the file input */
.custom-file-upload input[type="file"] {
  display: none;
}

.upload_image {
  display: flex;
  justify-content: center;
}

.upload_image img {
  width: 70%;
  border-radius: 12px;
}

.modalBackground {
  background-color: #e3e3e3;
}

.default_image {
  opacity: 0.3;
  min-height: 20rem;
  background: white;
  width: 80%;
  border-radius: 8px;
  display: flex;

}

.default_image span {
  color: green;
  opacity: 1 !important;
  margin: auto;

}

.dancingLoader {
  min-width: 400px;
  max-width: 400px;
  /* width: 50px; */
}
.dancingLoaderBtn {
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;
  /* width: 50px; */
}

.submitButton1 {
  border-radius: 8px;
  color: white;
  /* background-color: green; */
  margin: auto;
}
.submitButton1 img {
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
}

.submitButton1 span {
  margin-left: 0.7rem;
}
.image_header_logo img{
  min-height: 100px;
  max-height: 100px;
  
}
@media (max-width: 762px) {
  .modalBackground {
    display: flex;
    flex-direction: column;
  }

  .responsive_class1 {

    /* max-width:90% !important; */
  }

  .responsive_class {
    display: flex !important;
    overflow-x: hidden;
    /* max-width:90% !important; */
    flex-direction: column;
  }

  .form-check-input {
    font-size: x-large;
  }

  .success-container {
    margin-top: 1.5rem;
    width: 100%;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .rightImg {
    display: none;
  }

  .leftImg {
    display: none;
  }

  .submit_Form {
    width: 100%;
  }

  .custom-file-upload {
    padding: 0px 12px;
    margin-top: 0.5rem;
  }

  .upload_image img {
    width: 80%;
  }

  .submitButton {
    /* padding: 3px; */
    margin: auto;
  }



  .submitButton img {
    width: 15px;
  }

  .default_image{
    display: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}